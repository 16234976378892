import {
  EXPORT_CASHBOOK,
  GET_CASHBOOK,
  INIT_CASHBOOK_FILTER,
  SET_CASHBOOK_FILTER,
} from "@crema/types/actions/dairy/Report/Cashbook.actions";
import { IExportFile } from "@crema/types/models/APIDataProps";
import { ICashbook } from "@crema/types/models/dairy/Report/Cashbook";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialCashbookState = {
  cashbook: null as ICashbook,
  success: false as boolean,
  cashbookFilter: {},
  exportFile: null,
};

export const getCashbookAction = createAction<ICashbook>(GET_CASHBOOK);
export const initCashbookFilterAction = createAction(INIT_CASHBOOK_FILTER);
export const setCashbookFilterAction =
  createAction<object>(SET_CASHBOOK_FILTER);
export const exportCashbookAction = createAction<IExportFile>(EXPORT_CASHBOOK);

const cashbookReducer = createReducer(initialCashbookState, (builder) => {
  builder
    .addCase(getCashbookAction, (state, action) => {
      state.cashbook = action.payload;
    })
    .addCase(initCashbookFilterAction, (state) => {
      state.cashbookFilter = {};
      state.exportFile = null;
    })
    .addCase(setCashbookFilterAction, (state, action) => {
      state.cashbookFilter = action.payload;
    })
    .addCase(exportCashbookAction, (state, action) => {
      state.exportFile = action.payload;
    });
});

export default cashbookReducer;
