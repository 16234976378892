import { combineReducers } from "redux";
import Settings from "./Setting";
import Utility from "./Utility";
import Common from "./Common";
import UserList from "./UserList";
import Customer from "./Dairy/Customer";
import Chart from "./Dairy/Manage-Chart/Chart";
import FixedChart from "./Dairy/Manage-Chart/FixedChart";
import Milk from "./Dairy/Milk";
import productBrand from "./Dairy/Manage-Product/Product-Brand/Product-Brand";
import productGroup from "./Dairy/Manage-Product/Product-Group/Product-Group";
import product from "./Dairy/Manage-Product/Product";
import productUnit from "./Dairy/Manage-Product/Product-Units/Product-Unit";
import customerMilk from "./Dairy/Report/CustomerMilk";
import milkReport from "./Dairy/Report/MilkReport/MilkReport";
import customerPaymentReportReducer from "./Dairy/Report/CustomerPayment";
import customerProduct from "./Dairy/Manage-Customer/CustomerProducts/CustomerProducts";
import payment from "./Dairy/Manage-Customer/Payments";
import receipt from "./Dairy/Manage-Customer/Receipt/Receipt";
import Ledger from "./Dairy/Manage-Ledger/Ledger";
import LedgerGroup from "./Dairy/Manage-Ledger/LedgerGroup";
import ReceiptPayment from "./Dairy/Receipt-Payment/ReceiptPayment";
import dashboard from "./Dairy/Dashboard";
import user from "./SuperAdmin/User";
import dairyCustomer from "./SuperAdmin/DairyCustomer/DairyCustomer";
import companyDetail from "./Customer/Company";
import Tag from "./SuperAdmin/Tag";
import Bill from "./Dairy/Manage-Customer/Bill";
import Cashbook from "./Dairy/Report/Cashbook";

const reducers = () =>
  combineReducers({
    settings: Settings,
    utility: Utility,
    common: Common,
    userList: UserList,
    customer: Customer,
    chart: Chart,
    fixedChart: FixedChart,
    milk: Milk,
    productBrand: productBrand,
    productGroup: productGroup,
    product: product,
    productUnit: productUnit,
    customerMilk: customerMilk,
    milkReport: milkReport,
    customerProduct: customerProduct,
    payment: payment,
    receipt: receipt,
    dashboard: dashboard,
    user: user,
    dairyCustomer: dairyCustomer,
    companyDetail: companyDetail,
    customerPaymentReport: customerPaymentReportReducer,
    tag: Tag,
    ledger: Ledger,
    ledgerGroup: LedgerGroup,
    receiptPayment: ReceiptPayment,
    bill: Bill,
    cashbook: Cashbook,
  });
export default reducers;
