import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  IExportFile,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import { ICashbook } from "@crema/types/models/dairy/Report/Cashbook";
import {
  EXPORT_CASHBOOK,
  GET_CASHBOOK,
  INIT_CASHBOOK_FILTER,
  SET_CASHBOOK_FILTER,
} from "@crema/types/actions/dairy/Report/Cashbook.actions";

export const getCashbook = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cash-book", { params: filter })
      .then((response: APIResponse<ICashbook>) => {
        handleApiResponse(dispatch, response, GET_CASHBOOK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportCashbook = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/cash-book/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_CASHBOOK);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initCashbookFilter = () => (dispatch: Dispatch<AppActions>) => {
  dispatch({ type: INIT_CASHBOOK_FILTER });
};

export const setCashbookFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CASHBOOK_FILTER, payload: formData });
  };
