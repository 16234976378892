import { update } from "lodash";

export const authRole = {
  Admin: ["user", "admin"],
  Bank: [],
  User: ["user"],
};

export const defaultUser: any = {
  uid: "john-alex",
  displayName: "John Alex",
  email: "demo@example.com",
  token: "access-token",
  role: "user",
  photoURL: "/assets/images/avatar/A11.jpg",
};
export const allowMultiLanguage = process.env.REACT_APP_MULTILINGUAL === "true";
export const fileStackKey = process.env.REACT_APP_FILESTACK_KEY as string;
export const initialUrl = process.env.REACT_APP_INITIAL_URL as string; // this url will open after login

export const localStoragePrefix: string = "cobank_";

export const defaultDataTablePageLimit: number = 10;

export const paymentMode: Array<string> = [
  "Cash",
  "Cheque",
  "Net-banking",
  "UPI",
  "Online",
];
export const defaultDateFormat: string = "DD-MM-YYYY";
export const defaultDateTimeFormat = "DD-MM-YYYY HH:mm:ss";
export const defaultTimeFormat = "HH:mm:ss";
export const SITE_NAME = "My Coops";
export const BUCKET_URL = process.env?.REACT_APP_BUCKET_URL;

export const RoutePermittedRole = {
  admin: "admin",
  dairy: "bank",
  user: "user",
};
export const RoutePermission = {
  admin: {
    user: {
      create: "single-user",
      update: "update-user",
      list: "listing-user",
    },
    dairy: {
      create: "single-dairy",
      list: "listing-dairy",
    },
    tag: {
      create: "create-tag",
      update: "update-tag",
      delete: "delete-tag",
      view: "single-tag",
      list: "list-tag",
      attach: "add-user-tags",
    },
  },
  dairy: {
    customer: {
      create: "create-customer",
      update: "update-customer",
      list: "listing-customer",
      single: "single-customer",
      delete: "delete-customer",
      dropdwon: "single-customer-by-code-n-type",
    },
    chart: {
      create: "create-chart",
      update: "update-chart",
      single: "single-chart",
      list: "listing-chart",
      upload: "upload-chart",
      delete: "delete-chart",
    },
    milk: {
      create: "add-milk",
      update: "update-milk",
      single: "single-milk",
      delete: "delete-milk",
      list: "list-milk",
      checkMilk: "check-milk",
    },
    fixedRateChart: {
      create: "create-fixed-rate-chart",
      update: "update-fixed-rate-chart",
      single: "single-fixed-rate-chart",
    },
    report: {
      customerMilk: "customer-milk-report",
      milk: "milk-report",
      customerMilkExport: "customer-milk-report-export",
      customerMilkExportColumn: "customer-milk-report-export-column",
      milkExport: "milk-report-export",
      milkExportColumn: "milk-report-export-column",
      productExport: "product-report-export",
      paymentExport: "payment-report-export",
      receiptExport: "receipt-report-export",
      customerPaymentExport: "customer-payment-report-export",
      customerPaymentExportColumn: "customer-payment-report-column",
      customerPayment: "customer-payment-report",
      cashbook: "cashbook",
    },
    chartDetails: {
      add: "add-chart-details",
      get: "get-chart-details",
      delete: "delete-chart-details",
      bulk: "bulk-chart-details",
    },
    dairyPlan: {
      list: "listing-dairy-plan",
      single: "single-dairy-plan",
    },
    recharge: {
      create: "recharge",
      failed: "recharge-failed",
    },
    graphView: {
      dailyMilkCollection: "daily-milk-collection-graph-view",
      monthlyMilkCollection: "monthly-milk-collection-graph-view",
      yearlyTopTenCustomer: "yearly-top-ten-customer-graph-view",
      monthlyTopTenCustomer: "monthly-top-ten-customer-graph-view",
      totalCustomer: "total-customer-graph-view",
    },
    milkSync: {
      add: "add-milk-sync",
      update: "update-milk-sync",
    },
    productBrand: {
      create: "create-product-brand",
      update: "update-product-brand",
      single: "single-product-brand",
      list: "listing-product-brand",
      delete: "delete-product-brand",
    },
    productGroup: {
      create: "create-product-group",
      update: "update-product-group",
      single: "single-product-group",
      list: "listing-product-group",
      delete: "delete-product-group",
    },
    product: {
      create: "create-product",
      update: "update-product",
      single: "single-product",
      list: "listing-product",
      delete: "delete-product",
    },
    productUnit: {
      single: "single-product-unit",
      list: "listing-product-unit",
    },
    customerProduct: {
      create: "create-customer-product",
      update: "update-customer-product",
      single: "single-customer-product",
      list: "listing-customer-product",
      delete: "delete-customer-product",
    },
    customerPayment: {
      create: "create-customer-payment",
      update: "update-customer-payment",
      single: "single-customer-payment",
      list: "listing-customer-payment",
      delete: "delete-customer-payment",
    },
    customerReceipt: {
      create: "create-customer-receipt",
      single: "single-customer-receipt",
      list: "listing-customer-receipt",
      delete: "delete-customer-receipt",
      getDraft: "get-draft-customer-receipt",
      payment: "customer-receipt-payment",
      settle: "customer-receipt-settle",
    },
    company: {
      create: "create-company",
      update: "update-company",
      list: "listing-company",
      single: "single-company",
    },
    customerCompany: {
      list: "list-customer-companies",
      single: "single-customer-company",
    },
    ledger: {
      create: "create-ledger",
      update: "update-ledger",
      single: "single-ledger",
      list: "list-ledger",
      delete: "delete-ledger",
      dropdown: "dropdown-ledger",
    },
    ledgerGroup: {
      create: "create-ledger-group",
      update: "update-ledger-group",
      single: "single-ledger-group",
      list: "list-ledger-group",
      delete: "delete-ledger-group",
      dropdown: "dropdown-ledger-group",
    },
    receiptPayment: {
      create: "create-receipt-payment",
      update: "update-receipt-payment",
      single: "single-receipt-payment",
      list: "list-receipt-payment",
      delete: "delete-receipt-payment",
    },
    bill: {
      create: "create-bill",
      update: "update-bill",
      single: "single-bill",
      list: "list-bill",
      delete: "delete-bill",
    },
  },
};
