import {
  GET_MILK_REPORTS,
  INIT_ADD_MILK_REPORT,
  ADD_MILK_REPORT,
  EDIT_MILK_REPORT,
  DELETE_MILK_REPORT,
  GET_MILK_REPORT,
  GET_MILK_REPORT_LIST,
  INIT_MILK_REPORTS_FILTER,
  SET_MILK_REPORTS_FILTER,
  SET_EDIT_MILK_REPORT_ID,
  GET_MILK_REPORT_COLUMNS,
  EXPORT_MILK_REPORT,
} from "@crema/types/actions/dairy/Report/MilkReport/MilkReport";
import { IExportFile, ValidationError } from "@crema/types/models/APIDataProps";
import { IMilkReportColumns } from "@crema/types/models/dairy/Report/CustomerMilk";
import {
  IMilkReportDetails,
  IMilkReportList,
  IMilkReportShort,
  IGetMilkReport,
  IMilkReportPaginationType,
} from "@crema/types/models/dairy/Report/MilkReport/MilkReport";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialMilkReportState = {
  milkReports: [] as IMilkReportList[],
  pagination: null as IMilkReportPaginationType,
  milkReport: null as IMilkReportDetails | null,
  details: null as IMilkReportDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  milkReportList: [] as IMilkReportShort[],
  milkReportsFilter: {} as object | IMilkReportList,
  toggleToReload: false as boolean,
  milkReportId: 0 as number,
  exportFile: null as IExportFile,
  columns: null as IMilkReportColumns,
};

export const getMilkReportsAction =
  createAction<IGetMilkReport>(GET_MILK_REPORTS);
export const initAddMilkReportAction = createAction(INIT_ADD_MILK_REPORT);
export const addMilkReportAction =
  createAction<IMilkReportDetails>(ADD_MILK_REPORT);
export const editMilkReportAction = createAction(EDIT_MILK_REPORT);
export const deleteMilkReportAction =
  createAction<IMilkReportList>(DELETE_MILK_REPORT);
export const getMilkReportAction =
  createAction<IMilkReportDetails>(GET_MILK_REPORT);
export const getMilkReportListByBranchIdAction =
  createAction<IMilkReportShort[]>(GET_MILK_REPORT_LIST);
export const initMilkReportsFilter = createAction(INIT_MILK_REPORTS_FILTER);
export const setMilkReportsFilter = createAction<object>(
  SET_MILK_REPORTS_FILTER,
);
export const setEditProductBranchId = createAction<number>(
  SET_EDIT_MILK_REPORT_ID,
);
export const getMilkReportColumns = createAction<IMilkReportColumns>(
  GET_MILK_REPORT_COLUMNS,
);
export const exportMilkReport = createAction<IExportFile>(EXPORT_MILK_REPORT);

const milkReportReducer = createReducer(initialMilkReportState, (builder) => {
  builder
    .addCase(getMilkReportsAction, (state, action) => {
      state.milkReports = action.payload.reports;
      state.pagination = {
        total: Number(action.payload.pagination.totalCount),
        from: Number(action.payload.pagination.pageNumber),
        perPage: Number(action.payload.pagination.pageSize),
        ...action.payload.pagination,
      };
    })
    .addCase(initAddMilkReportAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addMilkReportAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editMilkReportAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
      // state.milkReportId = 0;
    })
    .addCase(deleteMilkReportAction, (state, action) => {
      state.milkReports = state.milkReports.filter(
        (milkReport) => milkReport.id !== action.payload.id,
      );
    })
    .addCase(getMilkReportAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getMilkReportListByBranchIdAction, (state, action) => {
      state.milkReportList = action.payload;
    })

    .addCase(initMilkReportsFilter, (state) => {
      state.milkReportsFilter = {};
    })
    .addCase(setMilkReportsFilter, (state, action) => {
      state.milkReportsFilter = action.payload;
    })
    .addCase(setEditProductBranchId, (state, action) => {
      state.milkReportId = action.payload;
    })
    .addCase(getMilkReportColumns, (state, action) => {
      state.columns = action.payload;
    })
    .addCase(exportMilkReport, (state, action) => {
      state.exportFile = action.payload;
    });
});

export default milkReportReducer;
