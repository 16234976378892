import React from "react";
import { FaFileInvoice, FaProductHunt, FaUserFriends } from "react-icons/fa";
import { AiFillCreditCard, AiOutlineBank, AiOutlineSend } from "react-icons/ai";
import IntlMessages from "@crema/helpers/IntlMessages";
import { BsGear } from "react-icons/bs";
import {
  BiGroup,
  BiIdCard,
  BiReceipt,
  BiSolidBookBookmark,
  BiSolidIdCard,
  BiSolidLayer,
  BiSolidLayerPlus,
} from "react-icons/bi";
import { RoutePermission } from "@crema/constants/AppConst";
import styled from "styled-components";
import {
  DollarCircleOutlined,
  FileTextOutlined,
  WalletOutlined,
} from "@ant-design/icons";

export const StyledMilkIcon = styled.img`
  width: 20px;
  height: 14px;
`;

const dairyRoutesConfig = [
  {
    id: "dashboard",
    messageId: "sidebar.app.dashboard",
    title: <IntlMessages id="sidebar.app.dashboard" />,
    type: "item",
    icon: <AiOutlineBank />,
    path: "/dashboard",
    permission: RoutePermission.dairy.graphView.totalCustomer,
  },
  {
    id: "buyMilk",
    title: <IntlMessages id="milk.buy" />,
    messageId: "milk.buy",
    type: "item",
    icon: (
      <img
        src={"/assets/icon/milk-1.svg"}
        alt="Milk Icone"
        style={{ background: "0000" }}
      />
    ),
    path: "/milk/buy",
    permission: RoutePermission.dairy.milk.list,
  },
  {
    id: "sellMilk",
    title: <IntlMessages id="milk.sell" />,
    messageId: "milk.sell",
    type: "item",
    icon: (
      <img
        src={"/assets/icon/milk-1.svg"}
        alt="Milk Icone"
        style={{ background: "0000" }}
      />
    ),
    path: "/milk/sell",
    permission: RoutePermission.dairy.milk.list,
  },
  {
    id: "sellerCustomer",
    title: <IntlMessages id="common.seller" />,
    messageId: "common.seller",
    type: "item",
    icon: <BiSolidIdCard />,
    path: "/customers/seller",
    permission: RoutePermission.dairy.customer.list,
  },
  {
    id: "buyerCustomer",
    title: <IntlMessages id="common.buyer" />,
    messageId: "common.buyer",
    type: "item",
    icon: <BiSolidIdCard />,
    path: "/customers/buyer",
    permission: RoutePermission.dairy.customer.list,
  },
  {
    id: "manage-customer",
    title: <IntlMessages id="common.manageCustomer" />,
    messageId: "common.manageCustomer",
    type: "group",
    children: [
      {
        id: "products",
        title: <IntlMessages id="products" />,
        messageId: "products",
        type: "item",
        icon: <FaProductHunt />,
        path: "/manage-customers/products",
        permission: RoutePermission.dairy.customerProduct.list,
      },
      {
        id: "payments",
        title: <IntlMessages id="payments" />,
        messageId: "payments",
        type: "item",
        icon: <AiFillCreditCard />,
        path: "/manage-customers/payments",
        permission: RoutePermission.dairy.customerPayment.list,
      },
      {
        id: "receipts",
        title: <IntlMessages id="receipts" />,
        messageId: "receipts",
        type: "item",
        icon: <BiReceipt />,
        path: "/manage-customers/receipt",
        permission: RoutePermission.dairy.customerReceipt.list,
      },
    ],
  },
  {
    id: "bills",
    title: <IntlMessages id="bills" />,
    messageId: "bills",
    type: "item",
    icon: <FaFileInvoice />,
    path: "/bills",
    permission: RoutePermission.dairy.customer.list,
  },
  {
    id: "manage-ledger",
    title: <IntlMessages id="manage.ledgers" />,
    messageId: "manage.ledgers",
    type: "group",
    children: [
      {
        id: "ledgers",
        title: <IntlMessages id="ledgers" />,
        messageId: "ledgers",
        type: "item",
        icon: <FileTextOutlined />,
        path: "/manage-ledger/ledgers",
        permission: RoutePermission.dairy.ledger.list,
      },
      {
        id: "receiptAndPayments",
        title: <IntlMessages id="receiptPayment" />,
        messageId: "receiptPayment",
        type: "item",
        icon: <WalletOutlined />,
        path: "/receipt-payments",
        permission: RoutePermission.dairy.receiptPayment.list,
      },
      {
        id: <IntlMessages id="cashbook" />,
        title: <IntlMessages id="cashbook" />,
        messageId: "cashbook",
        type: "item",
        icon: <BiSolidBookBookmark />,
        path: "/manage-report/cashbook",
        permission: RoutePermission.dairy.report.cashbook,
      },
    ],
  },
  {
    id: "Manage-Report",
    messageId: "common.manageReport",
    title: <IntlMessages id="common.manageReport" />,
    type: "group",
    children: [
      {
        id: "Customer Milk",
        title: <IntlMessages id="common.customerMilk" />,
        messageId: "common.customerMilk",
        type: "item",
        icon: <BiIdCard />,
        path: "/manage-report/customerMilk",
        permission: RoutePermission.dairy.report.customerMilk,
      },
      {
        id: "Milk Report",
        title: <IntlMessages id="common.milkReport" />,
        messageId: "common.milkReport",
        type: "item",
        icon: <BiSolidBookBookmark />,
        path: "/manage-report/milkReport",
        permission: RoutePermission.dairy.report.milk,
      },
      {
        id: <IntlMessages id="customerPaymentReport" />,
        title: <IntlMessages id="customerPaymentReport" />,
        messageId: "customerPaymentReport",
        type: "item",
        icon: <BiSolidBookBookmark />,
        path: "/manage-report/customer-payment-report",
        permission: RoutePermission.dairy.report.customerPayment,
      },
    ],
  },
  {
    id: "settings",
    title: <IntlMessages id="common.settings" />,
    messageId: "common.settings",
    type: "group",
    icon: <BsGear />,
    children: [
      {
        id: "manage-product",
        title: <IntlMessages id="common.manageProduct" />,
        messageId: "common.manageProduct",
        type: "collapse",
        permission: RoutePermission.dairy.product.list,
        children: [
          {
            id: "product",
            title: <IntlMessages id="product" />,
            messageId: "product",
            type: "item",
            icon: <BiSolidLayer />,
            path: "/manage-product/product",
            permission: RoutePermission.dairy.product.list,
          },
          {
            id: "productBrand",
            title: <IntlMessages id="ProductBrand" />,
            messageId: "productBrand",
            type: "item",
            icon: <BiSolidLayerPlus />,
            path: "/manage-product/product-brand",
            permission: RoutePermission.dairy.productBrand.list,
          },
          {
            id: "productGroup",
            title: <IntlMessages id="productGroup" />,
            messageId: "productGroup",
            type: "item",
            icon: <BiSolidLayerPlus />,
            path: "/manage-product/product-group",
            permission: RoutePermission.dairy.productGroup.list,
          },
        ],
      },
      {
        id: "charts",
        title: <IntlMessages id="common.manageChart" />,
        messageId: "common.manageChart",
        type: "collapse",
        children: [
          {
            id: "chart",
            title: <IntlMessages id="charts" />,
            messageId: "charts",
            type: "item",
            icon: <FaUserFriends />,
            path: "/charts",
            permission: RoutePermission.dairy.chart.list,
          },
          {
            id: "fixedChart",
            title: <IntlMessages id="chart.fixed" />,
            messageId: "chart.fixed",
            type: "item",
            icon: <FaUserFriends />,
            path: "/fixed-chart",
            permission: RoutePermission.dairy.fixedRateChart.single,
          },
        ],
      },
    ],
  },
];

export default dairyRoutesConfig;
