import { ICashbook } from "@crema/types/models/dairy/Report/Cashbook";

export const GET_CASHBOOK = "GET_CASHBOOK";
export const INIT_CASHBOOK_FILTER = "INIT_CASHBOOK_FILTER";
export const SET_CASHBOOK_FILTER = "SET_CASHBOOK_FILTER";
export const EXPORT_CASHBOOK = "EXPORT_CASHBOOK";

export type GetCashbookActions = {
  type: typeof GET_CASHBOOK;
  payload: ICashbook;
};
export type InitCashbookFilter = {
  type: typeof INIT_CASHBOOK_FILTER;
};

export type SetCashbookFilter = {
  type: typeof SET_CASHBOOK_FILTER;
  payload: object;
};

export type CashbookActions =
  | GetCashbookActions
  | InitCashbookFilter
  | SetCashbookFilter;
