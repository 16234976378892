import {
  GET_CUSTOMER_MILKS,
  INIT_ADD_CUSTOMER_MILK,
  ADD_CUSTOMER_MILK,
  EDIT_CUSTOMER_MILK,
  DELETE_CUSTOMER_MILK,
  GET_CUSTOMER_MILK,
  GET_CUSTOMER_MILK_LIST,
  INIT_CUSTOMER_MILKS_FILTER,
  SET_CUSTOMER_MILKS_FILTER,
  EXPORT_CUSTOMER_MILKS,
  COLUMNS_CUSTOMER_MILKS,
} from "@crema/types/actions/dairy/Report/CustomerMilk";
import {
  IColumns,
  IExportFile,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import {
  ICustomerMilkDetails,
  ICustomerMilkList,
  ICustomerMilkShort,
  IGetCustomerMilk,
} from "@crema/types/models/dairy/Report/CustomerMilk";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialCustomerMilkState = {
  customerMilks: [] as ICustomerMilkList[],
  pagination: null as PaginationType | null,
  customerMilk: null as ICustomerMilkDetails | null,
  details: null as ICustomerMilkDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  customerMilkList: [] as ICustomerMilkShort[],
  customerMilksFilter: {} as object | ICustomerMilkList,
  toggleToReload: false as boolean,
  exportFile: null as IExportFile,
  columns: null as IColumns,
};

export const getCustomerMilksAction =
  createAction<IGetCustomerMilk>(GET_CUSTOMER_MILKS);
export const initAddCustomerMilkAction = createAction(INIT_ADD_CUSTOMER_MILK);
export const addCustomerMilkAction =
  createAction<ICustomerMilkDetails>(ADD_CUSTOMER_MILK);
export const editCustomerMilkAction = createAction(EDIT_CUSTOMER_MILK);
export const deleteCustomerMilkAction =
  createAction<ICustomerMilkList>(DELETE_CUSTOMER_MILK);
export const getCustomerMilkAction =
  createAction<ICustomerMilkDetails>(GET_CUSTOMER_MILK);
export const getCustomerMilkListByBranchIdAction = createAction<
  ICustomerMilkShort[]
>(GET_CUSTOMER_MILK_LIST);
export const initCustomerMilksFilter = createAction(INIT_CUSTOMER_MILKS_FILTER);
export const setCustomerMilksFilter = createAction<object>(
  SET_CUSTOMER_MILKS_FILTER,
);
export const exportCustomerMilks = createAction<IExportFile>(
  EXPORT_CUSTOMER_MILKS,
);
export const columnsCustomerMilks = createAction<IColumns>(
  COLUMNS_CUSTOMER_MILKS,
);

const customerMilkReducer = createReducer(
  initialCustomerMilkState,
  (builder) => {
    builder
      .addCase(getCustomerMilksAction, (state, action) => {
        state.customerMilks = action.payload.reports;
        state.pagination = {
          total: action.payload.pagination.totalCount,
          from: action.payload.pagination.pageNumber,
          perPage: Number(action.payload.pagination.pageSize),
        };
      })
      .addCase(initAddCustomerMilkAction, (state) => {
        state.details = null;
        state.validationError = null;
        state.success = false;
      })
      .addCase(addCustomerMilkAction, (state, action) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })
      .addCase(editCustomerMilkAction, (state, action) => {
        state.success = true;
        state.toggleToReload = !state.toggleToReload;
      })
      .addCase(deleteCustomerMilkAction, (state, action) => {
        state.customerMilks = state.customerMilks.filter(
          (customerMilk) =>
            customerMilk.customerCode !== action.payload.customerCode,
        );
      })
      .addCase(getCustomerMilkAction, (state, action) => {
        state.details = action.payload;
      })
      .addCase(getCustomerMilkListByBranchIdAction, (state, action) => {
        state.customerMilkList = action.payload;
      })
      .addCase(initCustomerMilksFilter, (state) => {
        state.customerMilksFilter = {};
        state.exportFile = null;
      })
      .addCase(setCustomerMilksFilter, (state, action) => {
        state.customerMilksFilter = action.payload;
      })
      .addCase(exportCustomerMilks, (state, action) => {
        state.exportFile = action.payload;
      })
      .addCase(columnsCustomerMilks, (state, action) => {
        state.columns = action.payload;
      });
  },
);

export default customerMilkReducer;
