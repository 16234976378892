import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import {
  APIErrorResProps,
  APIResponse,
  IExportFile,
} from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import { IGetCustomerPaymentReport } from "@crema/types/models/dairy/Report/CustomerPayment";
import {
  COLUMNS_CUSTOMER_PAYMENT_REPORT,
  EXPORT_CUSTOMER_PAYMENT_REPORT,
  GET_CUSTOMER_PAYMENT_REPORT,
  INIT_CUSTOMER_PAYMENT_REPORT_FILTER,
  SET_CUSTOMER_PAYMENT_REPORT_FILTER,
} from "@crema/types/actions/dairy/Report/CustomerPayment";

export const getCustomerPaymentReport = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-payment-report", { params: filter })
      .then((response: APIResponse<IGetCustomerPaymentReport>) => {
        handleApiResponse(dispatch, response, GET_CUSTOMER_PAYMENT_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const exportCustomerPaymentReport = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-payment-report/export", { params: filter })
      .then((response: APIResponse<IExportFile>) => {
        handleApiResponse(dispatch, response, EXPORT_CUSTOMER_PAYMENT_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const columnsCustomerPaymentReport = (filter?: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/customer-payment-report/columns", { params: filter })
      .then((response: APIResponse<IGetCustomerPaymentReport>) => {
        handleApiResponse(dispatch, response, COLUMNS_CUSTOMER_PAYMENT_REPORT);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const initCustomerPaymentReportFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_CUSTOMER_PAYMENT_REPORT_FILTER });
  };

export const setCustomerPaymentReportFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CUSTOMER_PAYMENT_REPORT_FILTER, payload: formData });
  };
