import {
  IDairyCustomerDetails,
  IDairyCustomerInteraction,
  IDairyCustomerList,
  IDairyCustomerShort,
  IDairyCustomerTag,
} from "@crema/types/models/superAdmin/dairyCustomer/dairyCustomer";

export const GET_DAIRY_CUSTOMERS = "GET_DAIRY_CUSTOMERS";
export const INIT_ADD_DAIRY_CUSTOMER = "INIT_ADD_DAIRY_CUSTOMER";
export const ADD_DAIRY_CUSTOMER = "ADD_DAIRY_CUSTOMER";
export const EDIT_DAIRY_CUSTOMER = "EDIT_DAIRY_CUSTOMER";
export const DELETE_DAIRY_CUSTOMER = "DELETE_DAIRY_CUSTOMER";
export const GET_DAIRY_CUSTOMER = "GET_DAIRY_CUSTOMER";
export const GET_DAIRY_CUSTOMER_LIST = "GET_DAIRY_CUSTOMER_LIST";
export const INIT_DAIRY_CUSTOMERS_FILTER = "INIT_DAIRY_CUSTOMERS_FILTER";
export const SET_DAIRY_CUSTOMERS_FILTER = "SET_DAIRY_CUSTOMERS_FILTER";
export const GET_DAIRY_CUSTOMERS_FILTER = "GET_DAIRY_CUSTOMERS_FILTER";
export const GET_DAIRY_CUSTOMER_INTERACTION = "GET_DAIRY_CUSTOMER_INTERACTION";
export const GET_DAIRY_CUSTOMER_TAGS = "GET_DAIRY_CUSTOMER_TAGS";
export const ADD_DAIRY_CUSTOMER_TAG = "ADD_DAIRY_CUSTOMER_TAG";
export const EDIT_DAIRY_CUSTOMER_TAG = "EDIT_DAIRY_CUSTOMER_TAG";
export const DELETE_DAIRY_CUSTOMER_TAG = "DELETE_DAIRY_CUSTOMER_TAG";

export type GetDairyCustomersActions = {
  type: typeof GET_DAIRY_CUSTOMERS;
  payload: IDairyCustomerList[];
};

export type InitAddDairyCustomerActions = {
  type: typeof INIT_ADD_DAIRY_CUSTOMER;
};

export type AddDairyCustomerActions = {
  type: typeof ADD_DAIRY_CUSTOMER;
  payload: IDairyCustomerDetails;
};

export type EditDairyCustomerActions = {
  type: typeof EDIT_DAIRY_CUSTOMER;
  payload: IDairyCustomerDetails;
};

export type GetDairyCustomerActions = {
  type: typeof GET_DAIRY_CUSTOMER;
  payload: IDairyCustomerDetails;
};

export type GetDairyCustomerListActions = {
  type: typeof GET_DAIRY_CUSTOMER_LIST;
  payload: IDairyCustomerShort[];
};

export type DeleteDairyCustomerActions = {
  type: typeof DELETE_DAIRY_CUSTOMER;
  payload: IDairyCustomerList;
};

export type InitDairyCustomersFilter = {
  type: typeof INIT_DAIRY_CUSTOMERS_FILTER;
};

export type SetDairyCustomersFilter = {
  type: typeof SET_DAIRY_CUSTOMERS_FILTER;
  payload: object;
};

export type GetDairyCustomersFilterActions = {
  type: typeof GET_DAIRY_CUSTOMERS_FILTER;
  payload: IDairyCustomerList[];
};

export type GetDairyCustomerInteractionActions = {
  type: typeof GET_DAIRY_CUSTOMER_INTERACTION;
  payload: IDairyCustomerInteraction;
};

export type GetDairyCustomerTagsActions = {
  type: typeof GET_DAIRY_CUSTOMER_TAGS;
  payload: IDairyCustomerTag[];
};

export type AddDairyCustomerTagActions = {
  type: typeof ADD_DAIRY_CUSTOMER_TAG;
  payload: IDairyCustomerTag;
};

export type EditDairyCustomerTagActions = {
  type: typeof EDIT_DAIRY_CUSTOMER_TAG;
  payload: IDairyCustomerTag;
};

export type DeleteDairyCustomerTagActions = {
  type: typeof DELETE_DAIRY_CUSTOMER_TAG;
  payload: number;
};

export type DairyCustomerActions =
  | GetDairyCustomersActions
  | InitAddDairyCustomerActions
  | AddDairyCustomerActions
  | EditDairyCustomerActions
  | DeleteDairyCustomerActions
  | GetDairyCustomerActions
  | GetDairyCustomerListActions
  | InitDairyCustomersFilter
  | SetDairyCustomersFilter
  | GetDairyCustomerTagsActions
  | AddDairyCustomerTagActions
  | EditDairyCustomerTagActions
  | DeleteDairyCustomerTagActions;
