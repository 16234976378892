import { GET_FIXED_CHARTS } from "@crema/types/actions/dairy/Manage-Chart/FixedChart.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IFixedChartList,
  IGetFixedChart,
} from "@crema/types/models/dairy/Manage-Chart/FixedChart";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialFixedChartState = {
  fixedCharts: [] as IFixedChartList[],
  details: null as IFixedChartList | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  fixedChartList: [] as IFixedChartList[],
  fixedChartsFilter: {} as object,
};

export const getFixedChartsAction =
  createAction<IGetFixedChart>(GET_FIXED_CHARTS);

const fixedChartReducer = createReducer(initialFixedChartState, (builder) => {
  builder.addCase(getFixedChartsAction, (state, action) => {
    state.fixedCharts = action.payload.data;
  });
});

export default fixedChartReducer;
