import {
  GET_RECEIPTS,
  INIT_ADD_RECEIPT,
  ADD_RECEIPT,
  EDIT_RECEIPT,
  DELETE_RECEIPT,
  GET_RECEIPT,
  GET_RECEIPT_LIST,
  INIT_RECEIPTS_FILTER,
  SET_RECEIPTS_FILTER,
  SET_EDIT_RECEIPT_ID,
  EXPORT_RECEIPTS,
  GET_RECEIPT_DRAFT,
  SETTLE_RECEIPT,
  PAYMENT_RECEIPT,
} from "@crema/types/actions/dairy/Manage-Customer/Receipt/Receipt";
import {
  IExportFile,
  PaginationType,
  ValidationError,
} from "@crema/types/models/APIDataProps";
import {
  IReceiptDetails,
  IReceiptList,
  IReceiptShort,
  IGetReceipt,
  IReceiptDraft,
  IReceiptFooter,
} from "@crema/types/models/dairy/Manage-Customer/Receipt/Receipt";
import { createAction, createReducer } from "@reduxjs/toolkit";

const initialReceiptState = {
  receipts: [] as IReceiptList[],
  pagination: null as PaginationType | null,
  receiptFooter: null as IReceiptFooter | null,
  receipt: null as IReceiptDetails | null,
  details: null as IReceiptDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  receiptList: [] as IReceiptShort[],
  receiptsFilter: {
    pageNumber: 0,
    pageSize: 10,
  } as object,
  toggleToReload: false as boolean,
  receiptId: 0 as number,
  exportFile: null as IExportFile,
  draft: null as IReceiptDraft,
};

export const getReceiptsAction = createAction<IGetReceipt>(GET_RECEIPTS);
export const initAddReceiptAction = createAction(INIT_ADD_RECEIPT);
export const addReceiptAction = createAction<IReceiptDetails>(ADD_RECEIPT);
export const editReceiptAction = createAction<IReceiptDetails>(EDIT_RECEIPT);
export const settleReceiptAction =
  createAction<IReceiptDetails>(SETTLE_RECEIPT);
export const paymentReceiptAction =
  createAction<IReceiptDetails>(PAYMENT_RECEIPT);
export const deleteReceiptAction = createAction<IReceiptList>(DELETE_RECEIPT);
export const getReceiptAction = createAction<IReceiptDetails>(GET_RECEIPT);
export const getReceiptListByBranchIdAction =
  createAction<IReceiptShort[]>(GET_RECEIPT_LIST);
export const initReceiptsFilter = createAction(INIT_RECEIPTS_FILTER);
export const setReceiptsFilter = createAction<object>(SET_RECEIPTS_FILTER);
export const setEditReceiptId = createAction<number>(SET_EDIT_RECEIPT_ID);
export const exportReceipts = createAction<IExportFile>(EXPORT_RECEIPTS);
export const getDraftsAction = createAction<IReceiptDraft>(GET_RECEIPT_DRAFT);

const receiptReducer = createReducer(initialReceiptState, (builder) => {
  builder
    .addCase(getReceiptsAction, (state, action) => {
      state.receipts = action.payload.payment;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: action.payload.pagination.pageSize,
      };
      state.receiptFooter = action.payload.pagination;
    })
    .addCase(getDraftsAction, (state, action) => {
      state.draft = action.payload;
    })
    .addCase(initAddReceiptAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addReceiptAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(editReceiptAction, (state, action) => {
      state.success = true;
      state.toggleToReload = !state.toggleToReload;
    })
    .addCase(settleReceiptAction, (state, action) => {
      state.success = true;
      state.details = action.payload;
    })
    .addCase(paymentReceiptAction, (state, action) => {
      state.success = true;
      state.details = action.payload;
    })
    .addCase(deleteReceiptAction, (state, action) => {
      state.receipts = state.receipts.filter(
        (receipt) => receipt.customerCode !== action.payload.customerCode,
      );
    })
    .addCase(getReceiptAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getReceiptListByBranchIdAction, (state, action) => {
      state.receiptList = action.payload;
    })
    .addCase(initReceiptsFilter, (state) => {
      state.receiptsFilter = {};
      state.exportFile = null;
    })
    .addCase(setReceiptsFilter, (state, action) => {
      state.receiptsFilter = action.payload;
    })
    .addCase(setEditReceiptId, (state, action) => {
      state.receiptId = action.payload;
    })
    .addCase(exportReceipts, (state, action) => {
      state.exportFile = action.payload;
    });
});

export default receiptReducer;
