import {
  IGetReceipt,
  IReceiptDetails,
  IReceiptDraft,
  IReceiptList,
  IReceiptShort,
} from "@crema/types/models/dairy/Manage-Customer/Receipt/Receipt";

export const GET_RECEIPTS = "GET_RECEIPTS";
export const GET_RECEIPT_DRAFT = "GET_RECEIPT_DRAFT";
export const INIT_ADD_RECEIPT = "INIT_ADD_RECEIPT";
export const ADD_RECEIPT = "ADD_RECEIPT";
export const EDIT_RECEIPT = "EDIT_RECEIPT";
export const DELETE_RECEIPT = "DELETE_RECEIPT";
export const SETTLE_RECEIPT = "SETTLE_RECEIPT";
export const PAYMENT_RECEIPT = "PAYMENT_RECEIPT";
export const GET_RECEIPT = "GET_RECEIPT";
export const GET_RECEIPT_LIST = "GET_RECEIPT_LIST";
export const INIT_RECEIPTS_FILTER = "INIT_RECEIPTS_FILTER";
export const SET_RECEIPTS_FILTER = "SET_RECEIPTS_FILTER";
export const GET_RECEIPTS_FILTER = "GET_RECEIPTS_FILTER";
export const SET_EDIT_RECEIPT_ID = "SET_EDIT_RECEIPT_ID";
export const EXPORT_RECEIPTS = "EXPORT_RECEIPTS";

export type GetReceiptsActions = {
  type: typeof GET_RECEIPTS;
  payload: IGetReceipt;
};

export type InitAddReceiptActions = {
  type: typeof INIT_ADD_RECEIPT;
};

export type AddReceiptActions = {
  type: typeof ADD_RECEIPT;
  payload: IReceiptDetails;
};

export type EditReceiptActions = {
  type: typeof EDIT_RECEIPT;
  payload: IReceiptDetails;
};

export type GetReceiptActions = {
  type: typeof GET_RECEIPT;
  payload: IReceiptDetails;
};

export type GetReceiptListActions = {
  type: typeof GET_RECEIPT_LIST;
  payload: IReceiptShort[];
};

export type DeleteReceiptActions = {
  type: typeof DELETE_RECEIPT;
  payload: IReceiptList;
};
export type SettleReceiptActions = {
  type: typeof SETTLE_RECEIPT;
  payload: IReceiptList;
};

export type PaymentReceiptActions = {
  type: typeof PAYMENT_RECEIPT;
  payload: IReceiptList;
};

export type InitReceiptsFilter = {
  type: typeof INIT_RECEIPTS_FILTER;
};

export type SetReceiptsFilter = {
  type: typeof SET_RECEIPTS_FILTER;
  payload: object;
};

export type GetReceiptsFilterActions = {
  type: typeof GET_RECEIPTS_FILTER;
  payload: IReceiptList[];
};
export type SetReceiptIdForEditActions = {
  type: typeof SET_EDIT_RECEIPT_ID;
  payload: number;
};
export type GetReceiptDraftActions = {
  type: typeof GET_RECEIPT_DRAFT;
  payload: IReceiptDraft;
};

export type ReceiptActions =
  | GetReceiptsActions
  | InitAddReceiptActions
  | AddReceiptActions
  | EditReceiptActions
  | DeleteReceiptActions
  | SettleReceiptActions
  | PaymentReceiptActions
  | GetReceiptActions
  | GetReceiptListActions
  | InitReceiptsFilter
  | SetReceiptsFilter
  | SetReceiptIdForEditActions
  | GetReceiptDraftActions;
