import {
  handleApiResponse,
  handleErrorResponse,
  fetchStart,
} from "toolkit/actions";
import { APIResponse } from "@crema/types/models/APIDataProps";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { AppActions } from "@crema/types/actions";
import { Dispatch } from "redux";
import {
  IDairyCustomerList,
  IDairyCustomerDetails,
  IGetDairyCustomer,
  IDairyCustomerInteraction,
} from "@crema/types/models/superAdmin/dairyCustomer/dairyCustomer";
import {
  ADD_DAIRY_CUSTOMER,
  DELETE_DAIRY_CUSTOMER,
  EDIT_DAIRY_CUSTOMER,
  GET_DAIRY_CUSTOMER,
  GET_DAIRY_CUSTOMERS,
  GET_DAIRY_CUSTOMER_INTERACTION,
  INIT_ADD_DAIRY_CUSTOMER,
  INIT_DAIRY_CUSTOMERS_FILTER,
  SET_DAIRY_CUSTOMERS_FILTER,
} from "@crema/types/actions/superAdmin/DairyCustomer/DairyCustomer.actions";
import { APIErrorResProps } from "@crema/types/models/APIDataProps";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getDairyCustomers = (filter: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get("/admin-api/dairy-customer", { params: filter })
      .then((response: APIResponse<IGetDairyCustomer>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMERS);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const createOrUpdateDairyCustomer = (
  method: "post" | "put",
  data: IDairyCustomerDetails,
  id?: string | number,
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios[method](`/admin-api/dairy-customer${id ? `/${id}` : ""}`, data)
      .then((response: APIResponse<IDairyCustomerDetails>) => {
        handleApiResponse(
          dispatch,
          response,
          method === "post" ? ADD_DAIRY_CUSTOMER : EDIT_DAIRY_CUSTOMER,
        );
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const saveDairyCustomerInteraction = createAsyncThunk(
  "dairyCustomer/saveInteraction",
  async (
    { customerId, notes }: { customerId: string; notes: string },
    thunkAPI,
  ) => {
    try {
      const response = await jwtAxios.post("/admin-api/interaction", {
        customerId,
        notes,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
export const updateDairyCustomerNote = createAsyncThunk(
  "dairyCustomer/updateNote",
  async ({ id, notes }: { id: string | number; notes: string }, thunkAPI) => {
    try {
      const response = await jwtAxios.post(`/admin-api/interaction`, {
        customerId: id, // Use `customerId` instead of `dairyCustomerId`
        notes,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getDairyCustomerInteraction = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/dairy-customer/interaction/${id}`)
      .then((response: APIResponse<IDairyCustomerInteraction>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMER_INTERACTION);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deactivateOrDeleteDairyCustomer = (
  name: string,
  formData: { status: string; notes?: string },
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .post(`/admin-api/dairy-customer/delete/${name}`, formData)
      .then((response: APIResponse<null>) => {
        handleApiResponse(dispatch, response, DELETE_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const deleteDairyCustomer = (id: string | number, data: object) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .put(`/admin-api/dairy-customer/${id}`, data)
      .then((response: APIResponse<IDairyCustomerList>) => {
        handleApiResponse(dispatch, response, DELETE_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};

export const getDairyCustomerById = (id: string | number) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    jwtAxios
      .get(`/admin-api/dairy-customer/${id}`)
      .then((response: APIResponse<IDairyCustomerDetails>) => {
        handleApiResponse(dispatch, response, GET_DAIRY_CUSTOMER);
      })
      .catch((error: APIErrorResProps) => {
        handleErrorResponse(dispatch, error);
      });
  };
};
export const initAddDairyCustomer = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_ADD_DAIRY_CUSTOMER });
  };
};

export const initDairyCustomersFilter =
  () => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INIT_DAIRY_CUSTOMERS_FILTER });
  };

export const setDairyCustomersFilter =
  (formData: object) => (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_DAIRY_CUSTOMERS_FILTER, payload: formData });
  };

export const getDairyCustomerTags = createAsyncThunk(
  "dairyCustomer/getTags",
  async (_, thunkAPI) => {
    try {
      const response = await jwtAxios.get("/admin-api/tags");
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const addDairyCustomerTag = createAsyncThunk(
  "dairyCustomer/addTag",
  async (tagData: { name: string }, thunkAPI) => {
    try {
      const response = await jwtAxios.post("/admin-api/tags", tagData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const editDairyCustomerTag = createAsyncThunk(
  "dairyCustomer/editTag",
  async ({ id, name }: { id: number; name: string }, thunkAPI) => {
    try {
      const response = await jwtAxios.put(`/admin-api/tags/${id}`, { name });
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
export const updateDairyCustomerTag = createAsyncThunk(
  "dairyCustomer/updateTag",
  async (
    { id, tagId }: { id: string | number; tagId: string | number },
    thunkAPI,
  ) => {
    try {
      const response = await jwtAxios.post(`/admin-api/dairy-customer/tags`, {
        customerId: id,
        tagId,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteDairyCustomerTag = createAsyncThunk(
  "dairyCustomer/deleteTag",
  async (id: number, thunkAPI) => {
    try {
      await jwtAxios.delete(`/admin-api/tags/${id}`);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const createTag = createAsyncThunk(
  "tags/createTag",
  async (tagName: string, { rejectWithValue }) => {
    try {
      const response = await jwtAxios.post("/admin-api/tags", {
        name: tagName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);
