import {
  GET_CHARTS,
  INIT_ADD_CHART,
  ADD_CHART,
  EDIT_CHART,
  DELETE_CHART,
  GET_CHART,
  INIT_CHARTS_FILTER,
  SET_CHARTS_FILTER,
  GET_CHART_LIST,
  EDIT_CHART_RATE_DETAILS,
  UPLOAD_CHART_RATE_DETAILS,
} from "@crema/types/actions/dairy/Manage-Chart/Chart.actions";
import { ValidationError } from "@crema/types/models/APIDataProps";
import {
  IChartDetails,
  IChartList,
  IChartShort,
  IGetChart,
} from "@crema/types/models/dairy/Manage-Chart/Chart";
import { createAction, createReducer } from "@reduxjs/toolkit";

type PaginationType = {
  total: number;
  from: number;
  perPage: number;
};

const initialChartState = {
  charts: [] as IChartList[],
  pagination: null as PaginationType | null,
  chart: null as IChartDetails | null,
  details: null as IChartDetails | null,
  validationError: null as ValidationError | null,
  success: false as boolean,
  chartList: [] as IChartShort[],
  chartsFilter: {} as object,
};

export const getChartsAction = createAction<IGetChart>(GET_CHARTS);
export const initAddChartAction = createAction(INIT_ADD_CHART);
export const addChartAction = createAction<IChartDetails>(ADD_CHART);
export const editChartAction = createAction(EDIT_CHART);
export const editChartRateDetailsActions = createAction(
  EDIT_CHART_RATE_DETAILS,
);
export const uploadChartRateDetailsActions = createAction(
  UPLOAD_CHART_RATE_DETAILS,
);
export const deleteChartAction = createAction<IChartList>(DELETE_CHART);
export const getChartAction = createAction<IChartDetails>(GET_CHART);
export const getChartListByBranchIdAction =
  createAction<IChartShort[]>(GET_CHART_LIST);
export const initChartsFilter = createAction(INIT_CHARTS_FILTER);
export const setChartsFilter = createAction<object>(SET_CHARTS_FILTER);

const chartReducer = createReducer(initialChartState, (builder) => {
  builder
    .addCase(getChartsAction, (state, action) => {
      state.charts = action.payload.chart;
      state.pagination = {
        total: action.payload.pagination.totalCount,
        from: action.payload.pagination.pageNumber,
        perPage: Number(action.payload.pagination.pageSize),
      };
    })
    .addCase(initAddChartAction, (state) => {
      state.details = null;
      state.validationError = null;
      state.success = false;
    })
    .addCase(addChartAction, (state, action) => {
      state.chart = action.payload;
      state.success = true;
    })
    .addCase(editChartAction, (state, action) => {
      state.chart = action.payload as IChartDetails;
      state.charts = state.charts.map((chart) => {
        if (chart.id === (action.payload as IChartDetails).id) {
          return action.payload;
        }
        return chart;
      });
      state.success = true;
    })
    .addCase(editChartRateDetailsActions, (state, action) => {
      state.success = true;
    })
    .addCase(uploadChartRateDetailsActions, (state, action) => {
      state.success = true;
    })
    .addCase(deleteChartAction, (state, action) => {
      state.charts = state.charts.filter(
        (chart) => chart.id !== action.payload.id,
      );
    })
    .addCase(getChartAction, (state, action) => {
      state.details = action.payload;
    })
    .addCase(getChartListByBranchIdAction, (state, action) => {
      state.chartList = action.payload;
    })

    .addCase(initChartsFilter, (state) => {
      state.chartsFilter = {};
    })
    .addCase(setChartsFilter, (state, action) => {
      state.chartsFilter = action.payload;
    });
});

export default chartReducer;
