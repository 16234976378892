import { customerComponentRouteConfigs } from "./customers/route";
import { milkComponentRouteConfigs } from "./milk/route";
import { dashboardComponentRouteConfigs } from "./dashboard/route";
import { manageProductRouteConfigs } from "./manage-product/route";
import { reportRouteConfigs } from "./Report/route";
import { manageCustomerRouteConfigs } from "./manage-customer/route";
import { manageChartRouteConfigs } from "./manage-chart/route";
import { ledgerComponentConfigs } from "./manage-ledger/ledgers/route";
import { ledgerGroupComponentRouteConfigs } from "./manage-ledger/ledger-group/route";
import { receiptPaymentsComponentRouteConfigs } from "./receipt-payment/route";
import { billComponentRouteConfigs } from "./bill/route";
import { cashbookComponentRouteConfigs } from "./Report/Cashbook/route";

export const dairyRouteConfigs = [
  ...customerComponentRouteConfigs,
  ...manageChartRouteConfigs,
  ...milkComponentRouteConfigs,
  ...dashboardComponentRouteConfigs,
  ...manageProductRouteConfigs,
  ...reportRouteConfigs,
  ...manageCustomerRouteConfigs,
  ...ledgerComponentConfigs,
  ...ledgerGroupComponentRouteConfigs,
  ...receiptPaymentsComponentRouteConfigs,
  ...billComponentRouteConfigs,
  ...cashbookComponentRouteConfigs,
];
