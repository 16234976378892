import React from "react";

const List = React.lazy(() => import("./list"));
// const Add = React.lazy(() => import("./add"));
// const Edit = React.lazy(() => import("./edit"));
const View = React.lazy(() => import("./view"));

export const userComponentRouteConfigs = [
  {
    path: "/super-admin/users",
    element: <List />,
  },
  //   {
  //     path: "/manage-product/product/add",
  //     element: <Add />,
  //   },
  //   {
  //     path: "/manage-product/product/edit/:id",
  //     element: <Edit />,
  //   },
  {
    path: "/super-admin/users/view/:id",
    element: <View />,
  },
];
